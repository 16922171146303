import React, { FC } from 'react'
import Typography from '@mui/material/Typography';
import { Title } from '../../../common/types';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface IArticleTitleDetailsProps {
    title: Title
}

const ArticleTitleDetails: FC<IArticleTitleDetailsProps> = (props) => {
    const { title } = props

    const formattedDate = (dateString?: string) => {
        if (dateString === undefined || dateString == null || dateString === "") { return "" }
        const date = new Date(dateString)
        return date.toDateString()
    }

    const formattedDateToDisplay = formattedDate(title.releaseDate)
    console.log(`formattedDateToDisplay - ${formattedDateToDisplay}`)
    return (
        <>
            <Grid2 container justifyContent="space-between">
                {formattedDateToDisplay && <Typography variant='body1' gutterBottom color="text.secondary">
                    {formattedDateToDisplay}
                </Typography>}
                {
                    title.genres && title.genres?.length > 0 && (
                        <Typography variant="body1" gutterBottom color="text.secondary">
                            {title.genres.join(', ')}
                        </Typography>
                    )
                }
            </Grid2>
        </>
    )
}

export default ArticleTitleDetails