import React, { FC, useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom'
import { ClientContext } from 'react-fetching-library';
import TitleCarousel from './TitleCarousel';
import { HomepageResponse, Title } from '../../common/types';
import { getHomepageAction } from './actions';
import TitleGrid from './TitleGrid';

const HomeContentContainer: FC = () => {

    // state
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<HomepageResponse>()

    const navigate = useNavigate()
    const clientContext = useContext(ClientContext);

    const action = getHomepageAction()

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await clientContext.query(action)
            const { payload } = response
            if (payload) {
                setResponse(payload)
            }
            setLoading(false)
        })()

    }, [])

    const navigateToDetail = (title: Title) => {
        navigate(`${title.titleId}/review`)
    }

    return (
        <>
            <Box padding={2}>
                <TitleGrid
                    headerTitle={"Latest"}
                    itemsPerPage={4}
                    titles={response?.latest?.map((article) => { return article.title }) ?? []}
                    onClick={navigateToDetail}
                />
            </Box>
        </>
    )
}


export default HomeContentContainer