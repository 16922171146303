

const toUpperCaseEachWirdFirstLetter = (text?: string) => {
    if (text === undefined || text === null) { return "" }

    return text
        .split(' ')
        .map((word) => { return word[0].toUpperCase() + word.substring(1) })
        .join(' ')
}


export {
    toUpperCaseEachWirdFirstLetter
}