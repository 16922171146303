import React, { FC } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Title } from '../../common/types'
import { formattedDate } from '../../common/utilities/date-utilities';
import { toUpperCaseEachWirdFirstLetter } from '../../common/utilities/string-utilities';


interface TitleGridProps {
    headerTitle: string
    titles: Title[] | []
    itemsPerPage?: number
    onClick: (title: Title) => void
}

const TitleGrid: FC<TitleGridProps> = (props) => {
    const { titles, onClick } = props
    return (
        <>
            <Grid container spacing={2} marginRight={0}>
                {titles.map((title: Title) => (
                    <Grid sm={6} xs={12} md={6} lg={3}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <TitleCard title={title} onClick={onClick} />
                        </Box>
                    </Grid>
                ))}

            </Grid>
        </>
    )
}


const TitleCard: FC<{ title: Title, onClick: (title: Title) => void }> = (props) => {

    const { title, onClick } = props
    return (
        <>
            <Card sx={{ minWidth: 345, maxWidth: 345 }}>
                <CardActionArea onClick={() => { onClick(title) }} >
                    <CardMedia
                        component="img"
                        height="200"
                        image={`https://images.thyview.com/public/attachments/${title.titleId}.jpeg`}
                        alt={title.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" color="primary">
                            {toUpperCaseEachWirdFirstLetter(title.name)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formattedDate(title.releaseDate)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}

export default TitleGrid