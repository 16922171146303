import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const SEOContent: FC<{ title: string, description: string, type: string }> = (props) => {

    const { title, description, type } = props
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta property='og:type' content={type} />
                <meta property="og:description" content={description} />

                <meta name="twitter:creator" content={"@Thyview"} />
                <meta name="twitter:card" content={type} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
        </>
    )
}

export default SEOContent