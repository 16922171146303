import { Card, CardMedia, CardContent, Typography } from '@mui/material'
import React, { FC } from 'react'

interface IMainImageProps {
    titleId: string
}

const MainImage: FC<IMainImageProps> = (props) => {
    const { titleId } = props
    const url = `https://images.thyview.com/public/attachments/${titleId}.jpeg`
    return (
        <>
            <Card sx={{ maxWidth: "100%" }}>
                <CardMedia
                    component="img"
                    width= "100%"
                    image={url}
                    onError={(i: any) => i.target.src=''}
                />
            </Card>
        </>
    )
}

export default MainImage