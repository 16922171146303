import React, { FC, useState, useEffect, useContext } from "react";
import { ClientContextProvider } from "react-fetching-library";
import AppBar from "../common/modules/AppBar";
import { Client } from "../services/api/client";

interface IMainContainerProps {
    children: any
}

const MainContainer: FC<IMainContainerProps> = (props) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <>
            <ClientContextProvider client={Client}>
                <AppBar />
                {props.children}
            </ClientContextProvider>
        </>
    )
}

export default MainContainer