import React, { FC, useState, useEffect, useContext } from 'react'
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from "react-router-dom";
import thyviewLogo from '../resources/assets/thyview-logo.png'
import Grid2 from '@mui/material/Unstable_Grid2';

const AppBar: FC = () => {

    const navigate = useNavigate()

    const handleLogoTap = (event: React.MouseEvent<HTMLElement>) => {
        navigate("/")
    };


    return (
        <>
            <MuiAppBar position="static" enableColorOnDark>
                <Grid2 container justifyContent="center">
                    <Toolbar>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleLogoTap}
                            color="inherit"
                        >
                            <img src={thyviewLogo} width={100} height={33} />
                        </IconButton>
                    </Toolbar>
                </Grid2>
            </MuiAppBar>
        </>
    )
}

export default AppBar