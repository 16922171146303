import { Typography } from '@mui/material'
import React, { FC } from 'react'
import HomeContentContainer from './HomeContentContainer'


export const HomeContainer: FC = () => {
    return (
        <>
            <HomeContentContainer />
        </>
    )
}