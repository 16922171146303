import React, { FC } from 'react'
import Typography from '@mui/material/Typography';

interface IArticleBodyProps {
    content: string
}

const ArticleBody: FC<IArticleBodyProps> = (props) => {
    const { content } = props
    return (
        <>
            <Typography align="justify" >
                {content && content.split("\n").map((i, key) => {
                    return <p key={key}>{i}</p>;
                })}
            </Typography>
        </>
    )
}

export default ArticleBody