import React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import ArticleDetailContainer from "./Modules/ArticleDetail/ArticleDetailContainer";
import { HomeContainer } from "./Modules/Home/HomeContainer";
import MainContainer from './Navigation/MainContainer';
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import theme from "./common/themes";

export const AppRouter = () => {
    return (
        <>
            <BrowserRouter>
                <HelmetProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <MainContainer>
                            <Routes>
                                <Route>
                                    <Route path="/:id/review" element={<ArticleDetailContainer />} />
                                </Route>
                                <Route>
                                    <Route path="*" element={<HomeContainer />} />
                                </Route>
                            </Routes>
                        </MainContainer>
                    </ThemeProvider>
                </HelmetProvider>
            </BrowserRouter>
        </>
    )
}


export default AppRouter