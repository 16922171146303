import React, { FC } from 'react'
import Typography from '@mui/material/Typography';
import { toUpperCaseEachWirdFirstLetter } from '../../../common/utilities/string-utilities';

interface IArticleTitleProps {
    title: string
}

const ArticleTitle: FC<IArticleTitleProps> = (props) => {
    const { title } = props
    const displayTitle = toUpperCaseEachWirdFirstLetter(title)

    return (
        <>
            {displayTitle && <Typography variant='h3' gutterBottom color="primary">
                {displayTitle}
            </Typography>}
        </>
    )
}

export default ArticleTitle