import { createClient } from 'react-fetching-library';

import { requestHostInterceptor } from './interceptors';

const HOST = 'https://tgmk7m7ktj.execute-api.us-east-1.amazonaws.com/prd';

export const Client = createClient({
  requestInterceptors: [
    requestHostInterceptor(HOST)
  ]
});