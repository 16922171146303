import React, { FC, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ClientContext } from 'react-fetching-library';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Unstable_Grid2'
import IconButton from '@mui/material/IconButton';
import MainImage from './components/MainImage'
import ArticleRating from './components/Rating'
import ArticleBody from './components/Review'
import ArticleTitle from './components/Header'
import { ArticleInfo } from '../../common/types'
import { getArticleAction } from './actions'
import ArticleTitleDetails from './components/TitleDetails';
import BackIcon from '@mui/icons-material/ArrowBack'
import SEOContent from '../../common/components/SEO';
interface IArticleDetailContainerProps {

}

const ArticleDetailContainer: FC<IArticleDetailContainerProps> = () => {
    // state
    const [loading, setLoading] = useState(false);
    const [article, setArticle] = useState<ArticleInfo>()
    // params
    const { id } = useParams()

    const clientContext = useContext(ClientContext);
    const action = getArticleAction({ titleId: id })


    useEffect(() => {
        (async () => {
            if (!!id) {
                setLoading(true)
                const response = await clientContext.query(action)
                const { payload } = response
                if (payload) {
                    setArticle(payload)
                }
                setLoading(false)
            }
        })()

    }, [])

    return (
        <>
            {article && <Article article={article} />}
        </>
    )
}



interface IArticleProps {
    article: ArticleInfo
}
const Article: FC<IArticleProps> = (props) => {
    const { article } = props
    const navigate = useNavigate()
    if (!article || !article.title) {
        navigate(`/`)
    }

    const handleBackTap = (event: React.MouseEvent<HTMLElement>) => {
        navigate("/")
    };
    const seoDescription = `${article.title.name}: Review - ${article.content.substring(0, 170)}`
    return (
        <>
            <SEOContent
                title={article.title.name}
                description={seoDescription}
                type={"summary_large_image"}
            />
            <Box style={{ marginTop: 8 }} >
                <IconButton
                    size="large"
                    aria-label="back button"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleBackTap}
                    color="primary"
                >
                    <BackIcon />
                </IconButton>
            </Box>
            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="top"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
                marginTop={-5}
            >
                <Grid lg={6} md={10} sm={10} xs={12}>
                    <>
                        {article && article.title && <Box m={4} >
                            <ArticleTitle title={article.title.name} />
                            <MainImage titleId={article.titleId} />
                            {/* <Divider /> */}
                            <ArticleTitleDetails title={article.title} />
                            <ArticleRating rating={article.title.rating} />
                            <Divider />
                            <ArticleBody content={article.content} />
                        </Box>}
                    </>
                </Grid>
            </Grid>
        </>
    )
}

export default ArticleDetailContainer