import { RequestInterceptor } from 'react-fetching-library';
 

export const requestHostInterceptor: (host: string) => RequestInterceptor = host => () => async action => {

  console.log("In requestHostInterceptor")
  return {
    ...action,
    endpoint: `${host}${action.endpoint}`,
    headers: { 
      ...action.headers
    }
  };
};