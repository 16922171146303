import React, { FC } from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';

interface IArticleRatingProps {
    rating?: number
}


const ArticleRating: FC<IArticleRatingProps> = (props) => {
    const { rating } = props
    return (
        <>
            {rating &&
                <>
                    <Grid2 container alignItems="center">
                        <Typography variant='subtitle1' color="primary" fontWeight={"600"} fontStyle="italic">
                            Thyview Rating:
                            </Typography>
                        <Grid2>
                            <Stack spacing={1} direction="row" alignContent="center" padding={0} margin={0}>
                                <Stack spacing={1}>
                                    <Rating name="half-rating" value={rating} precision={0.1} readOnly />
                                </Stack>
                            </Stack>
                        </Grid2>
                        <Grid2>

                        </Grid2>
                        <Grid2>
                            <Typography variant='subtitle1' color="primary" fontWeight={"600"} fontStyle="italic">
                                {rating} out of 5
                            </Typography>
                        </Grid2>
                    </Grid2>
                </>
            }
        </>
    )
}

export default ArticleRating